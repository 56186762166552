.projects {
    display: flex;
    justify-content: center;
    margin-top: 5%;
}

.projects-container {
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    max-height: 800px;
}

.projects-container img{
    height: 50%;
    width: 40%;
    margin-top: 10%;
    margin-left: 2%;
}

.realisation {
    display: flex;
    align-items: center;
    flex-direction: row;
}

.realisation p {
    margin-left: 1rem;
    margin-right: auto;
}

@media (max-width: 992px) {
    .realisation {
        display: flex;
        align-items: center;
        flex-direction: column;
    }

    .projects-container img{
        height: 50%;
        width: 80%;
        margin-top: 10%;
        margin-left: 2%;
    }
}